import React, { useEffect, useState } from 'react';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { createTodo } from './graphql/mutations';
import { listTodos } from './graphql/queries';
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const initialState = { email: '' };

const App = ({ signOut, user }) => {
  const [formState, setFormState] = useState(initialState);
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    fetchTodos();
  }, []);

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listTodos));
      const todos = todoData.data.listTodos.items;
      setTodos(todos);
    } catch (err) {
      console.log('error fetching todos');
    }
  }

  async function makeApiRequest() {
    try {
      const email = { ...formState };
      const payload = {
        emailAddress: "ravenscuba@gmail.com",
      };
      const path = `/${user.username}/emailverify`; // Replace with the actual API endpoint path
      const response = await API.post('tenant', path, {body: payload});
      console.log('API response:', response);
    } catch (error) {
      console.log('Error making API request:', error);
    }
  }
  
  return (
    <div style={styles.container}>
      <nav style={styles.navbar}>
        <div style={styles.navbarBrand}>Form App</div>
        <Button onClick={signOut} style={styles.button}>Sign out</Button>
      </nav>
      <div style={styles.content}>
        <Heading level={1}>Hello {user.username}</Heading>
        <h2>Verify email</h2>
        <div style={styles.emailContainer}>
          <input
            onChange={event => setInput('email', event.target.value)}
            style={styles.input}
            value={formState.email}
            placeholder="Email"
          />
          <Button onClick={makeApiRequest} style={styles.sendButton}>Send</Button>
        </div>
        {
          todos.map((todo, index) => (
            <div key={todo.id ? todo.id : index} style={styles.todo}>
              <p style={styles.todoName}>{todo.name}</p>
              <p style={styles.todoDescription}>{todo.description}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  navbar: {
    backgroundColor: '#f8f8f8',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
  },
  navbarBrand: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginRight: 'auto',
  },
  content: {
    flex: 1,
    padding: '20px',
  },
  todo: {
    marginBottom: 15,
  },
  input: {
    border: 'none',
    backgroundColor: '#ddd',
    marginBottom: 10,
    padding: 8,
    fontSize: 18,
    width: '100%',
  },
  todoName: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  todoDescription: {
    marginBottom: 0,
  },
  button: {
    backgroundColor: 'black',
    color: 'white',
    outline: 'none',
    fontSize: 18,
    padding: '12px 0px',
    marginBottom: 10,
  },
  emailContainer: {
    display: 'flex',
    width: '100%',
  },
  sendButton: {
    backgroundColor: 'black',
    color: 'white',
    outline: 'none',
    fontSize: 18,
    padding: '12px 20px',
    marginLeft: 10,
  },
};

export default withAuthenticator(App);

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://c7bebzfnxzcbdf4z2y52n7r2mq.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "tenant",
            "endpoint": "https://3bq3or8z0c.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:0a920ac1-164e-4c79-ad1a-2a2a5d192470",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_vb9koCVT2",
    "aws_user_pools_web_client_id": "at56gtd2mkicu17bcto3ieu1o",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "forminfo-dev",
            "region": "eu-west-2"
        },
        {
            "tableName": "tenantinfo-dev",
            "region": "eu-west-2"
        },
        {
            "tableName": "tierinfo-dev",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
